<template lang="pug">
.subscribe(v-if="!loading")

  h1 Sua assinatura

  SubscriptionCheckoutForm(
    ref="checkoutForm",
    :data="checkoutData",
    :customerCPF="customer ? customer.cpf : ''",
    :shippingAddressInfo="shippingAddress")

  .actions
    Button.large(label="Assinar", @click="subscribe", rounded, :loading="isProcessingSubscription")

</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { TrackEventOptions } from '@gtm-support/vue-gtm'

import type { SubscriptionCheckoutFormModel } from '~/components/subscription/SubscriptionCheckoutForm.vue'

import type { Customer } from '~/services/customer/customerService'
import type { ShippingAddress } from '~/services/shippingAddress/shippingAddressService'
import type { Subscription } from '~/services/subscription/subscriptionService'
import type { VindiTokenizationResponse } from '~/services/vindi/vindiService'

import { v4 as uuidv4 } from 'uuid'

import { useGtm } from '@gtm-support/vue-gtm'

import SubscriptionCheckoutForm from '~/components/subscription/SubscriptionCheckoutForm.vue'

import CustomerService from '~/services/customer/customerService'
import PancakeService from '~/services/pancake/pancakeService'
import { planAliasToId, planBasePrice } from '~/services/subscription/subscriptionService'
import FacebookPixelService from '~/services/facebook/facebookPixelService'
import VindiService from '~/services/vindi/vindiService'

import { useActiveSubscription } from '~/store/subscription/subscription'

definePageMeta({
  requiresAuth: true
})

const config = useConfig()
const route = useRoute()
const router = useRouter()
const gtm = useGtm()
const notifications = useNotifications()
const activeSubscription = useActiveSubscription()

const billingPlatformId = config.billingPlatformId

const loading = ref(true)
const isProcessingSubscription = ref(false)

const customer = ref<Customer>()
const shippingAddress = ref<ShippingAddress>()

const couponCodes = ref<string[]>()

const checkoutForm = ref<InstanceType<typeof SubscriptionCheckoutForm> | null>()

const transactionId = uuidv4()

const checkoutData = reactive<SubscriptionCheckoutFormModel>({
  plan: null as string | null,
  creditCardInformation: {
    cardIssuer: "",
    cardNumber: "",
    cardExpiration: "",
    cardVerificationCode: "",
    cardHolderName: "",
    installments: 1
  },
  discountCouponId: null as string | null,
  giftCoupon: null,
  promotions: [] as string[],
  subscriptionGiftId: null as string | null
})

const loadQueryParameters = () => {
  const planQueryParam = route.query["plan"]
  if (typeof planQueryParam === "string") {
    checkoutData.plan = planQueryParam
  }

  const couponQueryParam = route.query["coupon"]
  if (typeof couponQueryParam === "string") {
    couponCodes.value = [couponQueryParam]
  } else if (Array.isArray(couponQueryParam)) {
    couponCodes.value = couponQueryParam as string[]
  }

  const subscriptionGiftIdQueryParam = route.query["subscriptionGiftId"]
  if (typeof subscriptionGiftIdQueryParam === "string") {
    checkoutData.subscriptionGiftId = subscriptionGiftIdQueryParam
  }
}

const emitBeginCheckoutEvent = () => {
  try {
    const gtmDataLayer = gtm?.dataLayer()

    if (gtmDataLayer) {
      const planId = checkoutData.plan ? planAliasToId(checkoutData.plan, checkoutData.discountCouponId != null) : null
      const planPrice = planId ? planBasePrice[planId] : null

      gtmDataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          "transaction_id": transactionId,
          "currency": "BRL",
          "value": planPrice ? planPrice / 100.0 : null,
          "coupon": couponCodes.value?.[0],
          "payment_method": 'CREDIT_CARD',
          "items": [{
            "item_id": planId,
            "item_name": planId,
            "price": planPrice ? planPrice / 100.0 : null,
            "quantity": 1
          }]
        }
      })
    }
  } catch (e) {
    captureException(new Error('Failed to track get begin checkout event', { cause: e }))
  }
}

const trackGtmEvent = (options: TrackEventOptions) => {
  try {
    gtm?.trackEvent(options)
  } catch (e) {
    captureException(new Error('Failed to track gtm event', { cause: e }))
  }
}

const emitPurchaseEvent = (subscription: Subscription) => {
  try {
    const gtmDataLayer = gtm?.dataLayer()

    if (gtmDataLayer) {
      const planId = checkoutData.plan ? planAliasToId(checkoutData.plan, checkoutData.discountCouponId != null) : null
      const planPrice = planId ? planBasePrice[planId] : null

      gtmDataLayer.push({
        event: "purchase",
        ecommerce: {
          "transaction_id": transactionId,
          "currency": "BRL",
          "value": planPrice ? planPrice / 100.0 : null,
          "coupon": checkoutData.discountCouponId ?? checkoutData.giftCoupon?.couponId ?? null,
          "shipping": subscription.shippingCost / 100.0,
          "payment_method": 'CREDIT_CARD',
          "items": [{
            "item_id": planId,
            "item_name": planId,
            "price": planPrice ? planPrice / 100.0 : null,
            "quantity": 1
          }]
        }
      })
    }
  } catch (e) {
    captureException(new Error('Failed to track gtm purchase event', { cause: e }))
  }
}

const subscribe = async () => {
  const checkoutIsValid = await checkoutForm.value?.validate() ?? false

  if (checkoutIsValid) {
    isProcessingSubscription.value = true

    const planType = checkoutData.plan
    const planId = planAliasToId(checkoutData.plan ?? 'monthly', checkoutData.discountCouponId != null)
    const creditCard = checkoutData.creditCardInformation

    try {

      var vindiTokenizationResponse: VindiTokenizationResponse | null = null
      try {
        vindiTokenizationResponse = checkoutForm ?
          await VindiService.tokenizeCreditCard({
            holderName: creditCard.cardHolderName,
            cardNumber: creditCard.cardNumber,
            cardExpiration: creditCard.cardExpiration,
            cardCVV: creditCard.cardVerificationCode,
            paymentCompanyCode: creditCard.cardIssuer
          }) : null

        trackGtmEvent({
          category: "credit_card_verification",
          action: "successful",
          label: "from_registration"
        })

        gtmPush({ event: "credit-card-verified" })
      } catch (error: FetchError | any) {
        trackGtmEvent({
          category: "credit_card_verification",
          action: "failed",
          label: "from_registration"
        })

        gtmPush({ event: "credit-card-verification-failed" })

        if ("status" in error && error.status == 422) {
          notifications.error("Cartão de crédito inválido. Por favor, verifique os dados do cartão de crédito e tente novamente")

          return
        } else {
          captureException(new Error('Failed to verify credit card', { cause: error }))

          return
        }
      }

      const facebookPixelAttribution = FacebookPixelService.getPixelAttribution()

      var fg = await fingerprint()

      const response = await PancakeService.subscribe({
        billingPlatformId: billingPlatformId,
        customerId: customer.value!.id,
        shippingAddressId: shippingAddress.value!.id,
        paymentProfile: {
          creditCardToken: vindiTokenizationResponse?.gatewayToken ?? null
        },
        subscription: { planId: planId, couponId: checkoutData.discountCouponId ?? null, giftCoupon: checkoutData.giftCoupon ?? null },
        promotions: checkoutData.promotions,
        mediaAttribution: null, // TODO
        facebookAttribution: {
          transactionId: transactionId,
          eventSourceUrl: "https://boxmagenta.com.br/minha-conta/assinar",
          fbp: facebookPixelAttribution.fbp,
          fbc: facebookPixelAttribution.fbc
        },
        fingerprint: fg
      })

      emitPurchaseEvent(response.subscription)

      trackGtmEvent({
        category: "subscription",
        action: "created",
        label: "already_registered"
      })

      gtmPush({ event: "subscribed", planId, planType })

      notifications.success("Assinatura realizada com sucesso!")

      await activeSubscription.refresh()

      router.replace({ name: "dashboard" })
    } catch (error: FetchError | any) {
      if ("status" in error && error.status == 403) {
        captureException(new Error("Forbidden to create a subscription", { cause: error }))

        gtmPush({ event: "subscription-failed", planId, planType, reason: "forbidden" })
      } else if ("status" in error && error.status == 429) {
        captureException(new Error("New subscription creation rate limited", { cause: error }))

        gtmPush({ event: "subscription-failed", planId, planType, reason: "rate_limited" })
      } else {
        captureException(new Error('Failed to create subscription', { cause: error }))

        gtmPush({ event: "subscription-failed", planId, planType, reason: "unknown" })
      }

      notifications.error("Houve um problema ao processar sua assinatura. Por favor, tente novamente mais tarde")
    } finally {
      isProcessingSubscription.value = false
    }
  }
}

onMounted(async () => {
  loading.value = true

  loadQueryParameters()
  emitBeginCheckoutEvent()

  await Promise.all([
    CustomerService.findSelfCustomer(),
    CustomerService.findSelfShippingAddresses()
      .catch((error: FetchError) => {
        if (error.status == 404) {
          return null
        } else {
          throw error
        }
      }),
    CustomerService.findSelfPrimarySubscription()
      .catch((error: FetchError) => {
        if (error.status == 404) {
          return null
        } else {
          throw error
        }
      }),
  ])
    .then(([customerResponse, shippingAddressesResponse, primarySubscription]) => {
      customer.value = customerResponse

      shippingAddress.value = shippingAddressesResponse?.sort((a, b) => {
        // sort by id desc
        return b.id - a.id
      })?.pop()

      if (primarySubscription != null) {
        notifications.warning("Você já possuí uma assinatura ativa")

        router.replace({ name: "dashboard" })
      }
    })
    .catch((error) => {
      if ("status" in error && (error.status == 401 || error.status == 403)) {
        notifications.warning("Acesse sua conta para assinar")
      } else {
        captureException(new Error('Failed to load user data', { cause: error }))

        notifications.error("Não foi possível carregar os dados da sua conta. Por favor, tente novamente")
      }
    })

  if (couponCodes.value) {
    setTimeout(() => {
      couponCodes.value?.forEach((couponCode) => {
        checkoutForm.value?.applyCoupon(couponCode, true)
      }, 200)
    })
  }

  if (!shippingAddress.value) {
    router.replace({ name: "edit-shipping-address", query: { returnTo: route.fullPath } })
  } else {
    loading.value = false
  }
})
</script>
<style lang="sass" scoped>
.subscribe
  position: relative
  width: 100%
  max-width: 1228px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  .page
    &.slim
      max-width: 680px
      margin: 0 auto

  .actions
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin-top: 32px
    height: 64px

    .loading
      height: 50px

    .large
      text-align: center
      font-size: 24px
      width: 220px
</style>
